.activreCss {
  border-color: cornflowerblue !important;
}
.stairList {
  position: relative;
  width: 24%;
  float: left;
  margin-right: 1%;
  padding: 4px 0;
  border: 1px solid #f3f3f3;
  border-radius: 4px;
  margin-bottom: 10px;
}
.stairList p {
  line-height: 26px;
  padding-left: 26px;
}
.stairList p span {
  font-size: 18px;
  color: cornflowerblue;
  padding-left: 6px;
}
#qrcode {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 14px;
  width: 150px;
  margin: auto;
}
#qrcode img {
  background-color: #999;
}
