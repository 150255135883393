



































































































































































































































































































































































































































































































































.activreCss {
  border-color: cornflowerblue !important;
}
.stairList {
  position: relative;
  width: 24%;
  float: left;
  margin-right: 1%;
  padding: 4px 0;
  border: 1px solid #f3f3f3;
  border-radius: 4px;
  margin-bottom: 10px;
  p {
    line-height: 26px;
    padding-left: 26px;
    span {
      font-size: 18px;
      color: cornflowerblue;
      padding-left: 6px;
    }
  }
}
#qrcode {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 14px;
  width: 150px;
  margin: auto;
  img {
    background-color: #999; //设置白色背景色
  }
}
